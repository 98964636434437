import React from "react";
import Layout from "../../components/Layout";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import BackIcon from "../../img/icons/Arrow-back.svg";
import kona from "../../img/kona_med_blom.png";
import carrots from "../../img/Group.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${kona})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "1000px",
    marginTop: "-84px",
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();

  return (
    <Layout hideMenu={true}>
      <Grid container className="login-container">
        <Grid item md={6} xs={12} className="image-container">
          <div className={classes.image}></div>
        </Grid>
        <Grid item md={6} xs={12} className="form-container">
          <div className={classes.paper}>
            <h2 className="login-heading">Innskráning inn á þitt svæði</h2>
            <h3 className="login-subheading">Vinsamlegast skráðu þig inn </h3>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Netfang"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Lykilorð"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button variant="contained" color="primary" className="login-btn">
                Innskrá
              </Button>
            </form>
            <Link
              className="home-page-link"
              style={{
                marginTop: "30px",
              }}
              to="/"
            >
              <p>
                <BackIcon /> Til baka á forsíðu
              </p>
            </Link>
            <img
              src={carrots}
              style={{ height: "250px", marginTop: "50px" }}
            ></img>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
}
